import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import clientsAPI from "../../apis/clients";
import { PendingAction, RejectedAction } from "../types";

export const handleLoadClients = createAsyncThunk(
	"clients/load",
	(params: { page: number; [key: string]: any }) =>
		clientsAPI.fetchPage(params),
	{
		condition: (params, { getState }: any) => {
			const state = getState().clients;
			if (
				state.currentPage === params.page &&
				JSON.stringify(state.currentFilters) === JSON.stringify(params)
			)
				return false;
			return true;
		},
	}
);

export const handleShowClient = createAsyncThunk(
	"clients/show",
	(id: number) => clientsAPI.show(id),
	{
		condition: (id = 1, { getState }) =>
			getState().clients.entities[id] === undefined,
	}
);

export const handleStoreClient = createAsyncThunk(
	"clients/store",
	(data: StoreClient) => clientsAPI.store(data)
);

export const handleUpdateClient = createAsyncThunk(
	"clients/update",
	({ id, data }: { id: number; data: StoreClient }) =>
		clientsAPI.update(id, data)
);

export const handleUpdateClientStatus = createAsyncThunk(
	"clients/update-status",
	clientsAPI.updateStatus
);

const clientsAdapter = createEntityAdapter<Client>();

const slice = createSlice({
	name: "clients",
	initialState: {
		...clientsAdapter.getInitialState(),
		currentPage: null as number | null,
		currentFilters: {} as any,
		total: 0,
		isFetching: false,
		isStoring: false,
	},
	reducers: {
		clientAdded: clientsAdapter.addOne,
		clientRemoved: clientsAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadClients.fulfilled, (state, action) => {
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.currentFilters = action.meta.arg;
				state.total = action.payload.meta.total;
				clientsAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleStoreClient.fulfilled, (state, action) => {
				state.isStoring = false;
				clientsAdapter.addOne(state, action.payload.data);
			})
			.addCase(handleUpdateClient.fulfilled, (state, action) => {
				state.isStoring = false;
				clientsAdapter.setOne(state, action.payload.data);
			})
			.addCase(handleUpdateClientStatus.pending, (state, { meta }) => {
				const { id, status } = meta.arg;
				clientsAdapter.updateOne(state, { id, changes: { status } });
			})
			.addCase(handleUpdateClientStatus.rejected, (state, { meta }) => {
				const { id, status } = meta.arg;
				clientsAdapter.updateOne(state, {
					id,
					changes: { status: status === 1 ? 2 : 1 },
				});
			})
			.addMatcher<PendingAction>(
				(action) => /^clients\/(store|update)\/pending$/.test(action.type),
				(state) => {
					state.isStoring = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^clients\/(store|update)\/rejected$/.test(action.type),
				(state) => {
					state.isStoring = false;
				}
			)
			.addMatcher<PendingAction>(
				(action) => /^clients\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^clients\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

const { clientAdded, clientRemoved } = slice.actions;

export function handleDeleteClient(client: Client) {
	return (dispatch: any) => {
		dispatch(clientRemoved(client.id));
		return clientsAPI.destroy(client.id).catch(() => {
			dispatch(clientAdded(client));
		});
	};
}

export default slice.reducer;
