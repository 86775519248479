import {
	createAsyncThunk,
	createEntityAdapter,
	createSlice,
} from "@reduxjs/toolkit";
import wInstancesAPI from "../../apis/wInstances";
import { PendingAction, RejectedAction } from "../types";
// import adminsAPI from "../../apis/admins";

type Params = {
	page?: number;
	sortField?: string;
	sortOrder?: string;
	searchKey?: string;
};
export const handleLoadWInstances = createAsyncThunk(
	"wInstances/load",
	({ page = 1, sortField, sortOrder, searchKey }: Params) =>
		wInstancesAPI.fetchPage(page, sortField, sortOrder, searchKey),
	{
		condition: (
			{ page = 1, sortField, sortOrder, searchKey },
			{ getState }: any
		) => {
			const currentState = getState().wInstances;
			if (
				currentState.currentPage === page &&
				currentState.sortField === sortField &&
				currentState.sortOrder === sortOrder
			) {
				return false; // Don't fetch if the page and sorting criteria are the same
			}
			// if (getState().wInstances.currentPage === page) return false;
		},
	}
);

export const handleShowWInstance = createAsyncThunk(
	"wInstances/show",
	(id: number) => wInstancesAPI.show(id),
	{
		condition: (id, { getState }: any) => true,
	}
);

const wInstancesAdapter = createEntityAdapter<WInstance>();

const slice = createSlice({
	name: "wInstances",
	initialState: {
		...wInstancesAdapter.getInitialState(),
		currentPage: null as number | null,
		total: 0,
		isFetching: false,
		isStoring: false,
		isUpdating: false,
		sortField: undefined as string | undefined,
		sortOrder: undefined as string | undefined,
		updatingId: null as number | null,
	},
	reducers: {
		wInstanceStatusUpdated: (state, action) => {
			wInstancesAdapter.updateOne(state, action.payload);
		},
		wInstanceAdded: wInstancesAdapter.addOne,
		wInstanceRemoved: wInstancesAdapter.removeOne,
	},
	extraReducers: (builder) => {
		builder
			.addCase(handleLoadWInstances.fulfilled, (state, action) => {
				console.log(action);
				state.isFetching = false;
				state.currentPage = action.payload.meta.current_page;
				state.total = action.payload.meta.total;
				state.sortField = action.payload.meta.sortField;
				state.sortOrder = action.payload.meta.sortOrder;
				wInstancesAdapter.setAll(state, action.payload.data);
			})
			.addCase(handleShowWInstance.pending, (state, action) => {
				state.isUpdating = true;
				state.updatingId = action.meta.arg;
			})
			.addCase(handleShowWInstance.fulfilled, (state, action) => {
				state.isUpdating = false;
				state.updatingId = null;
				wInstancesAdapter.setOne(state, action.payload.data);
			})
			.addMatcher<PendingAction>(
				(action) => /^wInstances\/(load)\/pending$/.test(action.type),
				(state) => {
					state.isFetching = true;
				}
			)
			.addMatcher<RejectedAction>(
				(action) => /^wInstances\/(load)\/rejected$/.test(action.type),
				(state) => {
					state.isFetching = false;
				}
			);
	},
});

const { wInstanceStatusUpdated, wInstanceRemoved, wInstanceAdded } =
	slice.actions;

export function handleUpdateWInstanceStatus({
	id,
	status,
}: {
	id: number;
	status: "active" | "pending";
}) {
	return (dispatch: any, getState: any) => {
		const old = getState().wInstances.entities[id];
		dispatch(wInstanceStatusUpdated({ id, changes: { status } }));
		return wInstancesAPI.updateStatus(id, status).catch((error) => {
			dispatch(wInstanceStatusUpdated({ id, changes: { status: old.status } }));
		});
	};
}

export function handleDeleteWInstance(wInstance: WInstance) {
	return (dispatch: any) => {
		dispatch(wInstanceRemoved(wInstance.id));
		return wInstancesAPI.destroy(wInstance.id).catch(() => {
			dispatch(wInstanceAdded(wInstance));
		});
	};
}

export default slice.reducer;
