import { client } from ".";

async function fetchPage(
	page: number,
	sortField?: string,
	sortOrder?: string,
	searchKey?: string
) {
	const { data } = await client.get<ResultList<WInstance>>(
		`/whatsapp-instances`,
		{
			params: {
				page,
				sort_by: sortField,
				sort_type: sortOrder,
				search_key: searchKey,
			},
		}
	);
	return data;
}

async function show(id: number) {
	const { data } = await client.get<Result<WInstance>>(
		`/whatsapp-instances/${id}`
	);
	return data;
}

async function updateStatus(id: number, status: "active" | "pending") {
	const { data } = await client.put<Result<WInstance>>(
		`/whatsapp-instances/${id}/update-status`,
		{ status }
	);
	return data;
}

async function destroy(id: number) {
	const { data } = await client.delete(`/whatsapp-instances/${id}`);
	return data;
}

const wInstanceAPI = {
	fetchPage,
	show,
	updateStatus,
	destroy,
};

export default wInstanceAPI;
